




























import { Component, Vue } from 'vue-property-decorator';
import { Constants } from '@/constants';
import Campaign from '@/shared/models/campaign.interface';
import FormBuilder from '@/components/form-builder/FormBuilder.vue';
import LocaleSelection from '../components/LocaleSelection.vue';
import InputText from '@/components/form-builder/InputText.vue';

@Component({
    name: 'EditRegistration',
    components: { LocaleSelection, FormBuilder, InputText },
})
export default class EditRegistrationCheck extends Vue {
    private readonly locale: string;
    private readonly currentDate: Date;
    private readonly password: string;
    private readonly registrationId: string;
    private loading: boolean;
    private showForm: boolean;
    private campaignData: any = [];
    public formData: {}[] = [];
    private promiseData: any;

    constructor() {
        super();
        this.loading = false;
        this.showForm = false;

        this.locale = this.$parent.$route.params.locale;
        if (this.locale) {
            this.setLocale(this.locale);
        }
        this.registrationId = this.$parent.$route.params.registrationId;
        this.currentDate = new Date();
        this.campaignData = [];
        this.formData = [];
        this.password = '';
        this.promiseData = null;

    }

    setLocale(locale: string): void {
        this.$parent.$i18n.locale = locale;
    }
    setLocaleId(localeId: string): void {
        this.$parent.$i18n.locale = localeId;
    }


    async getEditForRegistration(input: {}): Promise<any> {
        this.getEditForm(input).then(data => {
            const parsedData = JSON.parse(data);
            const fieldBlocks = [];
            for (let i = 0; i < parsedData.result.formbuilder.exportBlocks.length; i++) {
                const ArrayObjects = parsedData.result.formbuilder.exportBlocks[i].exportFields;
                for (let j = 0; j < ArrayObjects.length; j++) {
                    fieldBlocks.push(ArrayObjects[j]);
                }
            }
            this.campaignData.push(...fieldBlocks);
            this.loading = false;
            this.showForm = true;
            return fieldBlocks;
        });
    }

    async getEditForm(input: {}) {
        const url = `/Api/GetEditForm`;
        const getUpdateFormRequest = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'same-origin',
            body: JSON.stringify(input)
        });
        const getUpdateFormResponse = await getUpdateFormRequest.json();

        return getUpdateFormResponse;
    }

    goToRegistration(password: string): void {
        this.loading = true;
        this.getEditForRegistration({
            registrationId: Number(this.registrationId), password: password, locale: this.locale,
        });            
    }
}
